import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {fetchCandidateWith} from '../actionCreators';

const useCandidateUpdateTabs = (candidateId, tabIndex) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!candidateId) {
      return () => null;
    }

    const doFetchCandidateUpdates = () => {
      switch (tabIndex) {
        case 'details':
          return dispatch(
            fetchCandidateWith(
              candidateId,
              // eslint-disable-next-line max-len
              'firm_filters,registrations,admissions,current_address,current_address_id,subscriber_data,attachments,insights,is_health_sector,team_name_auto,team_name_reason,main_practice_area_auto,main_practice_area_reason'
            )
          );
        case 'history':
          return dispatch(fetchCandidateWith(candidateId, 'positions,education,languages,is_health_sector'));
        case 'expertise':
          return dispatch(
            fetchCandidateWith(
              candidateId,
              // eslint-disable-next-line max-len
              'practice_areas,areas_of_expertise,workmix,sectors,is_health_sector,team_name_auto,team_name_reason,main_practice_area_auto,main_practice_area_reason'
            )
          );
        case 'insights':
          return dispatch(fetchCandidateWith(candidateId, 'insights,is_health_sector'));
        case 'notes':
          return dispatch(fetchCandidateWith(candidateId, 'activity,is_health_sector'));
        default:
          return Promise.reject();
      }
    };

    doFetchCandidateUpdates();

    return () => null;
  }, [tabIndex, candidateId, dispatch]);
};

export default useCandidateUpdateTabs;
